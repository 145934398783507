* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
/*
.home,
.about-us,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about-us {
  background-image: url("images/sre_home_header_image.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about-us-heading {
  padding: 4rem;
  background: #fff;
}

.about-us-paragraph {
  padding: 2rem;
  background: #fff;
}

.products {
  background-image: url("images/sre_home_header_image.jpeg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url("images/sre_home_header_image.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */
